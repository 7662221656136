import HttpProxy from '@/services/HttpProxy';

export default class DeviceService {
    static BaseUrl = 'Devices';

    static async getAll(parameters) {
        const response = await HttpProxy.submit({
            method: 'get', url: this.BaseUrl, parameters,
        });
        return response?.data ?? null;
    }

    static async getById(deviceId) {
        const response = await HttpProxy.submit({
            method: 'get', url: `${this.BaseUrl}/${deviceId}`, parameters: {},
        });
        return response?.data ?? null;
    }

    static async post(data) {
        const response = await HttpProxy.submit({
            method: 'post', url: this.BaseUrl, data,
        });
        return response?.data ?? null;
    }

    static async put(deviceId, data) {
        const response = await HttpProxy.submit({
            method: 'put', url: `${this.BaseUrl}/${deviceId}`, data,
        });
        return response?.data ?? null;
    }

    static async softDelete(deviceId) {
        const response = await HttpProxy.submit({
            method: 'delete', url: `${this.BaseUrl}/${deviceId}`, parameters: {},
        });
        return response?.data ?? null;
    }

    static async fusion(data) {
        const response = await HttpProxy.submit({
            method: 'post', url: `${this.BaseUrl}/merge`, data,
        });
        return response?.data ?? null;
    }

    static async getDeviceTypes(parameters) {
        const response = await HttpProxy.submit({
            method: 'get', url: 'DeviceType', parameters,
        });
        return response?.data ?? null;
    }
}

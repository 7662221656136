<template>
  <div>
    <div class="w-full flex flex-row justify-between" v-loading.fullscreen.lock="loading">
      <div class="text-left">
        <h1 class="text-lg font-bold inline-flex">
          Dispositivos
        </h1>
      </div>
      <div class="text-right">
        <el-button type="primary" @click="addRow" :disabled="isAdding">Agregar</el-button>
        <el-button type="warning" :disabled="!areDevicesAbleToFusion" @click="openFusion">Fusionar</el-button>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full border-collapse">
        <thead>
          <tr class="px-2 border-b-2">
            <th class="pl-2 py-3 font-bold text-left border-l-0 border-r whitespace-nowrap"></th>
            <th class="pl-2 py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">Categoría</th>
            <th class="pl-2 py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">Marca</th>
            <th class="pl-2 py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">Modelo</th>
            <th class="pl-2 py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">Ordenes</th>
            <th class="pl-2 py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"></th>
          </tr>
          <tr v-if="isAdding">
            <td></td>
            <td>
              <el-select v-model="newDevice.deviceTypeId" placeholder="Categoría" class="w-full p-2">
                <el-option v-for="type in deviceTypes" :key="type.id" :label="type.name" :value="type.id"></el-option>
              </el-select>
            </td>
            <td><el-input v-model="newDevice.brand" placeholder="Marca" class="p-2"></el-input></td>
            <td><el-input v-model="newDevice.model" placeholder="Modelo" class="p-2"></el-input></td>
            <td></td>
            <td>
              <sa-group-action-button 
                  :showEdit="false"
                  :showDelete="false"
                  :showSave="true"
                  :showCancel="true"
                  @save="saveNewDevice(newDevice)"
                  @cancel="isAdding = false"
                ></sa-group-action-button>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="device in devices" :key="device.id">
            <td class="w-10 p-2 border-t border-r">
              <el-checkbox v-model="selectedDevices" :label="device.id">
                &nbsp;
              </el-checkbox>
            </td>
            <template v-if="editingDevices[device.id]">
              <td class="p-2 border-t">
                <el-select v-model="editingDevices[device.id].deviceType.id" class="w-full p-2">
                  <el-option v-for="type in deviceTypes" :key="type.id" :label="type.name" :value="type.id"></el-option>
                </el-select>
              </td>
              <td class="p-2 border-t">
                <el-input v-model="editingDevices[device.id].brand" class="p-2"></el-input>
              </td>
              <td class="p-2 border-t">
                <el-input v-model="editingDevices[device.id].model" class="p-2"></el-input>
              </td>
              <td class="p-2 border-t">{{ editingDevices[device.id].orders }}</td>
              <td class="w-24 p-2 border-t">
                <sa-group-action-button 
                  :showEdit="false"
                  :showDelete="false"
                  :showSave="true"
                  :showCancel="true"
                  @save="saveEdit(editingDevices[device.id])"
                  @cancel="cancelEdit(device.id)"
                ></sa-group-action-button>
              </td>
            </template>

            <template v-else>
              <td class="p-2 border-t">{{ device.deviceType.name }}</td>
              <td class="p-2 border-t">{{ device.brand }}</td>
              <td class="p-2 border-t">{{ device.model }}</td>
              <td class="p-2 border-t">{{ device.orders }}</td>
              <td class="w-24 p-2 border-t">
                <sa-group-action-button 
                  :disableDelete="device.orders > 0" 
                  @edit="onEdit(device)" 
                  @delete="onDelete(device)"
                ></sa-group-action-button>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <fusion
      :visible="showFusion"
      :devices="mapSelectedDevices"
      @on:close="closeFusion"
      @on:fusion="fusion"></fusion>
  </div>
</template>
<script>
import error from '@/mixins/error';
import DeviceService from '@/services/DeviceService';

export default {
  name: 'Devices',
  mixins: [error],
  components: {
    Fusion: () => import("@/components/dialogs/Fusion"),
  },
  data() {
    return {
      loading: false,
      showFusion: false,
      selectedDevices: [],
      devices: [],
      deviceTypes: [],
      isAdding: false,
      newDevice: {
        deviceTypeId: null,
        brand: '',
        model: '',
      },
      editingDevices: {},
    }
  },
  mounted() {
    this.load();
    this.loadDeviceTypes();
  },
  computed: {
    areDevicesAbleToFusion() {
      return this.selectedDevices.length > 1;
    },
    mapSelectedDevices() {
      return this.devices.filter((device) => this.selectedDevices.includes(device.id));
    },
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        this.devices = await DeviceService.getAll();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async loadDeviceTypes() {
      try {
        this.deviceTypes = await DeviceService.getDeviceTypes();
      } catch (error) {
        console.log(error);
      }
    },
    addRow() {
      this.isAdding = true;
    },
    buildPostRequest(device) {
      return {
        deviceTypeId: device.deviceTypeId,
        brand: device.brand,
        model: device.model,
      };
    },
    async saveNewDevice() {
      if (!this.newDevice.deviceTypeId || !this.newDevice.brand || !this.newDevice.model) {
        return;
      }
      this.loading = true;
      try {
        const data = this.buildPostRequest(this.newDevice);
        const createdDevice = await DeviceService.post(data);
        this.devices.push({
          id: createdDevice.id,
          deviceType: {
            id: createdDevice.deviceType.id,
            name: createdDevice.deviceType.name,
          },
          brand: createdDevice.brand,
          model: createdDevice.model,
          orders: 0,
        });
        this.isAdding = false;
        this.newDevice = { deviceTypeId: null, brand: '', model: '' };
        this.$toastr.s("Dispositivo creado correctamente");
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    onEdit(device) {
      this.$set(this.editingDevices, device.id, { ...device });
    },
    buildPutRequest(device) {
      return {
        device: {
          id: device.id,
          deviceTypeId: device.deviceType.id,
          brand: device.brand,
          model: device.model,
        },
      };
    },
    async saveEdit(device) {
      this.loading = true;
      try {
        const data = this.buildPutRequest(device);
        await DeviceService.put(device.id, data);
        this.devices = this.devices.map((current) => 
          current.id === device.id
            ? {
                ...device,
                deviceType: { ...(this.deviceTypes.find((dt) => dt.id === device.deviceType.id) || {}) },
              }
            : current
        );
        this.$delete(this.editingDevices, device.id);
        this.$toastr.s("Dispositivo actualizado correctamente");
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    cancelEdit(deviceId) {
      this.$delete(this.editingDevices, deviceId);
    },
    onDelete(device) {
      this.$confirm(`¿Seguro que quieres eliminar el dispositivo?
        <br/> <b>${device.brand} - ${device.model}</b>`, "Advertencia", {
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(async () => {
        this.loading = true;
        try {
          await DeviceService.softDelete(device.id);
          this.devices = this.devices.filter(({ id }) => id !== device.id);
          this.$toastr.s("El dispositivo se elimino correctamente");
        } catch (error) {
          const errorMessage = this.getErrorMessage(error);
          this.$toastr.e(errorMessage);
        } finally {
          this.loading = false;
        }
      }).catch(() => { });
    },
    openFusion() {
      if (this.areDevicesAbleToFusion) {
        this.showFusion = true;
      }
    },
    closeFusion() {
      this.showFusion = false;
      this.selectedDevices = [];
    },
    async fusion(deviceId) {
      this.loading = true;
      try {
        const deleteDeviceIds = this.selectedDevices.filter((id) => id !== deviceId);
        await DeviceService.fusion({ deviceId, deleteDeviceIds });
        const orders = this.devices
          .filter((device) => deleteDeviceIds.includes(device.id))
          .reduce((acc, curr) => acc + curr.orders, 0);
        this.devices = this.devices.filter(
          (device) => !deleteDeviceIds.includes(device.id)
        );
        this.devices = this.devices
          .map((device) => device.id === deviceId ? { ...device, orders: device.orders + orders } : device);
        this.selectedDevices = [];
        this.$toastr.s("Se fusionaron los dispositivos correctamente");
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
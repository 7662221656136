<template>
  <administration-layout>
    <div class="pt-12">
      <div class="text-xl font-bold">
        <h1>Catálogos</h1>
      </div>
    </div>
    <div class="flex flex-col items-center py-4">
      <div class="w-full xl:px-36 sm:px-8">
        <div class="flex flex-col space-y-6">
          <content-card>
            <devices />
          </content-card>
        </div>
      </div>
    </div>
  </administration-layout>
</template>
<script>
import error from '@/mixins/error';
import Devices from './devices';

export default {
  name: 'Catalogs',
  mixins: [
    error,
  ],
  components: {
    AdministrationLayout: () => import('@/components/organisms/TheAdministrationApp'),
    ContentCard: () => import('@/components/molecules/ContentCard'),
    Devices,
  },
  data() {
    return {
      loading: false,
    }
  },
}
</script>